import React, {useState} from "react";
import "../Default.css";
import "./Homepage.css";
import { Link } from "react-router-dom";

function Homepage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  return (
      <div className="container">
        <Link to="https://siea.dev/balu" className="balu-button">🐶</Link>
        <h1>Pixel Services</h1>
        <p className="tagline">Pixel-perfect solutions for the digital world.</p>

        <div className="services">
          <div className="service">
            <h2>Hosting</h2>
            <p>Reliable and secure hosting for your games.</p>
            <a href="https://hosting.pixel-services.com/" className="button">
              Learn More
            </a>
          </div>

          <div className="service">
            <h2>Character Studio</h2>
            <p>Manage and customize player appearances in real-time.</p>
            <a href="/character-studio" className="button">
              Learn More
            </a>
          </div>

          <div className="service">
            <h2>Software</h2>
            <p>Custom Minecraft software tailored to your needs.</p>
            <a href="/custom-software" className="button">
              Learn More
            </a>
          </div>
        </div>

        <div className="team">
          <h2>Our Team</h2>
          <p>Meet the talented individuals behind Pixel Services.</p>
          <div className="team-member left">
            <img src="/images/Sieadev.png" alt="Sieadev" className="team-photo"/>
            <div className="team-info">
              <div className="team-header">
                <h3>Sieadev</h3>
                <div className="social-links">
                  <a href="https://github.com/sieadev" target="_blank" rel="noopener noreferrer"><i
                      className="fab fa-github"></i></a>
                  <a href="https://discord.com/users/sieadev" target="_blank" rel="noopener noreferrer"><i
                      className="fab fa-discord"></i></a>
                  <a href="https://sieadev.com" target="_blank" rel="noopener noreferrer"><i
                      className="fas fa-globe"></i></a>
                </div>
              </div>
              <p>Head Developer</p>
              <div className="more-info">
                <p>
                  Hey, I'm Finley, often known as Siea or Sieadev. I'm a young developer,
                  a huge interest in Minecraft Development, and lots of passion for Java.
                  I'm the creator of <a href="https://github.com/sieadev/MoBot" target="_blank"
                                        rel="noopener noreferrer">MoBot</a>, <a
                    href="https://github.com/sieadev/Discord2FA" target="_blank"
                    rel="noopener noreferrer">Discord2FA</a>,
                  and many other projects.
                </p>
              </div>
            </div>
          </div>
          <div className="team-member right">
            <img src="/images/Relism.png" alt="Relism" className="team-photo"/>
            <div className="team-info">
              <div className="team-header">
                <div className="social-links">
                  <a href="https://github.com/relism" target="_blank" rel="noopener noreferrer"><i
                      className="fab fa-github"></i></a>
                  <a href="https://discord.gg/KTF3Wsk85G" target="_blank" rel="noopener noreferrer"><i
                      className="fab fa-discord"></i></a>
                  <a href="https://relimc.com" target="_blank" rel="noopener noreferrer"><i
                      className="fas fa-globe"></i></a>
                </div>
                <h3>Relism</h3>
              </div>
              <p>Head Developer</p>
              <div className="more-info">
                <p>
                  Hey, I'm Zac, a Junior developer with a strong passion for backend
                  technologies and Minecraft development. I created and maintain <a
                    href="https://github.com/relism/ServerLibraries" target="_blank"
                    rel="noopener noreferrer">ServerLibraries</a>, <a href="https://github.com/relism/PortForwarded"
                                                                      target="_blank"
                                                                      rel="noopener noreferrer">PortForwarded</a>, as
                  well as many other popular Minecraft projects.
                </p>
              </div>
            </div>
          </div>
          <div className="team-member left">
            <img src="/images/MisterGriimm.png" alt="MisterGriimm" className="team-photo"/>
            <div className="team-info">
              <div className="team-header">
                <h3>MisterGriimm</h3>
                <div className="social-links">
                  <a href="https://github.com/mistergriimm" target="_blank" rel="noopener noreferrer"><i
                      className="fab fa-github"></i></a>
                  <a href="https://discord.gg/KTF3Wsk85G" target="_blank" rel="noopener noreferrer"><i
                      className="fab fa-discord"></i></a>
                  <a href="https://mistergriimm.com" target="_blank" rel="noopener noreferrer"><i
                      class="fas fa-globe"></i></a>
                </div>
              </div>
              <p>Artist</p>
              <div className="more-info">
                <p>
                  Howdy folks! I’m Liam, (aka MisterGriimm). I’m an experienced 3D modeler
                  and digital artist with a passion for design, and a love of Minecraft.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="contact">
          <h2>Contact Us</h2>
          <p>Get in touch with us for your next project.</p>
          <form onSubmit={handleSubmit} className="contact-form">
            <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={formData.name}
                onChange={handleChange}
                required
            />
            <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={formData.email}
                onChange={handleChange}
                required
            />
            <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleChange}
                required
            />
            <button type="submit" className="button">Send</button>
          </form>
        </div>
      </div>
  );
}

export default Homepage;