// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './Default.css';
import Homepage from "./homepage/Homepage";
function App() {
    return (
        <>
            <div className="background"></div>
            <div className="overlay"></div>
            <Router>
                <Routes>
                    <Route path="/" element={< Homepage />} />
                </Routes>
            </Router>
        </>
    );
}

export default App;